<template>
  <router-view />
</template>

<style>
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Barlow', sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: condensed;
    user-select: none;
  }
  body{
    color: black;
    background: black;
  }
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
  }
  .fade-in-out-enter-active
   {
    animation: onoff 2.2s ease-in-out;
   }
  .fade-in-out-leave-active {
    animation: onoff 2.2s reverse; 
    opacity: 0;
  }
  
  @keyframes onoff {
    0% { opacity: 0; filter: brightness(0);}
    80% { opacity: 0.15; filter: brightness(1.5); }
    100% { opacity: 1; filter: brightness(1);}
  }
  @media screen and (min-width: 992px) {
    body{
    font-size: 22px;
    font-weight: 300;
    }
  }
</style>
