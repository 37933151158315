<template>
  <transition name="fade-in-out" appear>
    
    <section id="welcome" v-if='!showQuestions'>
      <div class="wrapper" :class="bulbAnimation"
      @animationend="changeBulbAnimation"
      >
        <div v-if="startingTime.startTime && startingTime.startTime!=='expired'" class="countdown">
          <ul>
            <li><span>{{days}}</span>nap</li>
            <li><span>{{hours}}</span>óra</li>
            <li><span>{{minutes}}</span>perc</li>
            <li><span>{{seconds}}</span>mp</li>
          </ul>
           <Partners>
           <template v-slot:header>
            <h2>Partnereink</h2>
           </template>
           <template v-slot:images>
            <a href="https://1552.hu/" target="_blank"><img src="/img/quiz/1552.png" alt="1552 BOUTIQUE HOTEL & RESTAURANT" title="1552 BOUTIQUE HOTEL & RESTAURANT" width=100></a>
           </template> 
           <template v-slot:links>
              <a  href="https://1552.hu/" target="_blank">1552 BOUTIQUE HOTEL & RESTAURANT</a>
           </template>
          </Partners>     
        </div>
        <div v-else-if="startingTime.startTime==='expired'">
          <h3 class="countdown">A játéknak vége</h3>
        </div>
        <div v-else-if="!goodbye" class="quiz-welcome">
          <h1><span class="logo-title-1">eso</span><span class="logo-title-2">guides</span><span class="logo-title-3"> kvíz</span></h1>
          <div>
              <h2>A kérdéssor helyes megfejtői között ajándékokat sorsolunk ki! </h2><br>
              <p style="color: yellow;">1 db 5000 Crown, 1 db 3000 Crown és 1db 2000 Crown értékű nyeremény üti a szerencsések markát.</p>
              <br>
              <p>A nyereményjátékban való részvételhez mindössze az e-mail címed kell megadnod. Ha kisorsolnak, erre a címre kapod az értesítést.</p>
          </div>
          <form action="" class="quiz-form" v-on:submit.prevent="onSubmit" novalidate>
              <label for="">
                  <input type="email" v-model="postData.email" @focus="emailInputFocused = true" @blur="emailBlur">
    
                  <span class="input-underline"></span>
                  <span v-if="!emailInputIsEmpty" class="input-label"></span>
                  <span v-else-if="emailInputFocused === null" class="input-label">E-mail</span>
                  <span v-else-if="!emailInputFocused" class="input-label input-label-anim-reverse">E-mail</span>
                  <span v-else-if="emailInputFocused"  class="input-label input-label-anim">E-mail</span>
              </label>
              <div v-if="responseData.value" class="error-text">{{responseData.value.errorEmail}}</div>
              <label for="checkbox" class="chk-class">
                  <input type="checkbox" id="checkbox" :checked="checked" @change="checkboxChanged"><span class="checkbox-span">A megadott adataim kezeléséhez hozzájárulok, a kapcsolattartásra vonatkozó <a href="https://esoguides.hu/kapcsolat-adatkezeles/" target="_blank">adatkezelési szabályzatot</a> megismertem, és elfogadom!</span>
                  </label>
                  <div v-if="responseData.value" class="error-text">{{responseData.value.errorGdpr}}</div>
              <button v-if="postData.email && checked && !loading" class="quiz-button-active" v-on:click="formSubmit()">Induljon a játék!</button>
              <button v-if="!postData.email || !checked || loading" class="quiz-button-inactive" >Induljon a játék!</button>
              <LoaderCircle v-if="postData.email && checked && loading" :loading="loading"></LoaderCircle>
          </form>
          <Partners>
           <template v-slot:header>
            <h2>Partnereink</h2>
           </template>
           <template v-slot:images>
            <a href="https://1552.hu/" target="_blank"><img src="/img/quiz/1552.png" alt="1552 BOUTIQUE HOTEL & RESTAURANT" title="1552 BOUTIQUE HOTEL & RESTAURANT" width=100></a>
           </template> 
           <template v-slot:links>
              <a  href="https://1552.hu/" target="_blank">1552 BOUTIQUE HOTEL & RESTAURANT</a>
           </template>
          </Partners>
        </div>
        <div v-else class="quiz-farewell">
          <h1><span class="logo-title-1">eso</span><span class="logo-title-2">guides</span><span class="logo-title-3"> kvíz</span></h1>
          <h2>Köszönjük, hogy velünk játszottál <span class="logo-title-4" style="color: yellow;">{{user}}</span>!</h2>
          <div>
              <h2>A válaszaid rögzítésre kerültek! </h2><br>
              <h2>Nyeremény esetén e-mailben értesítünk!</h2>
          </div>
          <button class="quiz-button-active" @click="bulbAnimation='fade-in-out-leave-active'"
          >Tovább az esoguides.hu-ra</button>  
            <Partners>
           <template v-slot:header>
            <h2>Partnereink</h2>
           </template>
           <template v-slot:images>
            <a href="https://1552.hu/" target="_blank"><img src="/img/quiz/1552.png" alt="1552 BOUTIQUE HOTEL & RESTAURANT" title="1552 BOUTIQUE HOTEL & RESTAURANT" width=100></a>
           </template> 
           <template v-slot:links>
              <a  href="https://1552.hu/" target="_blank">1552 BOUTIQUE HOTEL & RESTAURANT</a>
           </template>
          </Partners>     
        </div>
        <div class="parallax">
          <div id="group4" class="parallax-group">
              <div class="parallax-layer parallax-layer-base bulb-idle">
              </div>
              <div class="parallax-layer parallax-layer-front dust1-idle">
              </div>
              <div class="parallax-layer parallax-layer-deep dust2-idle">
              </div>
              <div class="parallax-layer parallax-layer-deepest wall-idle">
              </div>
          </div>
        </div>
      
      </div>
    </section>
  </transition>
</template>

<script>
import axios from "axios"
import Partners from "@/components/Partners.vue"
import LoaderCircle from "@/components/LoaderCircle.vue";
import { ref, reactive, onMounted, onUnmounted } from "vue";
export default {
  name: "Welcome",
  components: {
    LoaderCircle,
    Partners
  },
  props: {
      goodbye: { type: Boolean,
          default: false,
      },
      user: { type: String,
          default: '',
      }
  },
  setup( props, { emit } ) {
    const showQuestions = ref(false)
    const loading = ref(false)
    const bulbAnimation = ref('')
    const email = ref('')
    const startingTime = ref('')
    const countDown = ref('')
    const days = ref('00')
    const hours = ref('00')
    const minutes = ref('00')
    const seconds = ref('00')
    const checked = ref(0)
    const emailInputFocused = ref(null)
    const emailInputIsEmpty = ref(true)
    const responseData = reactive({
      errorEmail: '', errorGdpr: ''
    })
    const postData = reactive({
       email: '',
       gdpr: ''
      })
    onMounted(() =>
      {
        window.addEventListener("mousemove",mouseMoveEvent)
        getStartingTime()

      }
    ) 
    onUnmounted(() =>
      {
        window.removeEventListener("mousemove",mouseMoveEvent)
      }
    )
    
    function formSubmit() {
      loading.value = true;
      axios.post(process.env.VUE_APP_API_URL, postData)
      .then(response => {
        responseData.value = response.data
        if (typeof responseData.value === 'string') {
          showQuestions.value = true
          emit('authSuccess', postData.email)
        }
        loading.value = false
      })
      .catch(err => {
        emit('errorOccurred', err.message)
        loading.value = false  
      })
    }
    function getStartingTime() {
      loading.value = true;
       axios.get(process.env.VUE_APP_API_URL + '?time')
      .then(response => {
        startingTime.value = response.data
        loading.value = false
        if ( new Date(startingTime.value.endTime).getTime() < new Date().getTime()) {
          startingTime.value.startTime = 'expired'
        }
        else if ( new Date().getTime() > new Date(startingTime.value.startTime).getTime()) {
          startingTime.value.startTime = ''
        }
        else countDown.value = setInterval(remainingTime,1000)
        
      })
      .catch(err => {
        emit('errorOccurred', err.message)
        loading.value = false  
      })
    }
    function remainingTime(){
      let now = new Date().getTime()
      let distance = new Date(startingTime.value.startTime).getTime() - now
      
      days.value = String(Math.floor(distance / (1000 * 60 * 60 * 24))).padStart(2, "0")
      hours.value = String(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, "0")
      minutes.value = String(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, "0")
      seconds.value = String(Math.floor((distance % (1000 * 60)) / 1000)).padStart(2, "0")
      
      if (distance < 0) {
        clearInterval(countDown.value)
        days.value = '00'
        hours.value = '00'
        minutes.value = '00'
        seconds.value = '00'
        startingTime.value.startTime = ''
      }
    }
    function checkboxChanged(e){
    if (e.target.checked) checked.value = 1
    else checked.value = 0
    postData.gdpr = checked.value
    }  
    function emailBlur(){
      emailInputFocused.value = false
      if (postData.email == '') emailInputIsEmpty.value = true
      else emailInputIsEmpty.value = false
    }
    function mouseMoveEvent(e){
      const ele = document.querySelector(".parallax")
      let xPos = 0
      let yPos = 0
      xPos = e.pageX / 2
      yPos = e.pageY 

      ele.style.perspectiveOrigin = xPos + "px "+ yPos + "px"
      ele.style.transition = "perspective-origin 1.9s ease-out, perspective-origin-x 1.9s ease-out, perspective-origin-y 1.9s ease-out"
    }
    function changeBulbAnimation(e){
         if (bulbAnimation.value == 'fade-in-out-leave-active' && props.goodbye && e.animationName == 'onoff'){
             window.location.href = "https://esoguides.hu"
         } 
     }

    return { showQuestions, loading, formSubmit, email, responseData, checked, emailInputFocused, emailInputIsEmpty, checkboxChanged, emailBlur, bulbAnimation, changeBulbAnimation, postData, startingTime, days, hours, minutes, seconds}
  },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

 #welcome {
  height: auto;
  width: 100%;
  background: black;
  color: #fff;
  position: relative;
  max-height: 200vh;
  overflow: hidden;
}
.wrapper{
   margin: 0 auto;
}

.quiz-welcome{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;
  padding: 0 1.75rem; 
}
.countdown{
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: space-between;
  top: 50%;
  right: 40%;
  width: 100%;
  height: 40vh;
  transform: translate(50%,-50%);
  z-index: 1;
}

.countdown ul{
  list-style: none;
  padding-top: 4rem;
}
.countdown li, h3{
  font-size: 2.5rem;
  display: inline-block;
  padding-right: 1rem;

}
.countdown li span{
  display: block;
  font-size: 2.5rem;
  /* text-align: right; */
}
.quiz-farewell{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90vh;
  z-index: 1;
  padding: 0 1.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.bulb-idle{
  animation: bulbidle 7s ease-in 2.0s infinite forwards;
}
@keyframes bulbidle {
    0%, 100%{
        filter:  brightness(1.05) saturate(1.60) grayscale(0) hue-rotate(0deg);
        opacity: 1;}
    50%{filter:  brightness(0.55) saturate(1);
        opacity: .9;}
}
.wall-idle{
    animation: wallidle 7s ease-in 2.0s infinite forwards ;
}
@keyframes wallidle {
    0%, 100%{opacity: 1;}
    50%{opacity: .7;}
}
.dust1-idle{
    animation: dust1idle 7s ease-in 2.0s infinite forwards ;
}
@keyframes dust1idle {
    0%, 100%{opacity: .6;}
    50%{opacity: .3;}
}
.dust2-idle{
    animation: dust2idle 7s ease-in 2.0s infinite forwards ;
}
@keyframes dust2idle {
    0%, 100% {opacity: .6; }
    50%{ opacity: .2;}
}

h1 {
  margin-top: 0rem;
  }
h2 {
  font-weight: 300;
  font-size: 22px;
}

.quiz-welcome>div {
  margin-top: 1rem;
  text-align: left;
}
.quiz-welcome>form {
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}
.logo-title-1{
  font-size: 58px;
  font-weight: 500;
  color: orange;
}
.logo-title-2{
  font-size: 36px;
  font-weight: 500;
  color:orange;
  opacity: .75;
}
.logo-title-3{
  font-size: 32px;
  font-weight: 500;
  color: orange;
  opacity: .5;
    
}
.quiz-form{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.quiz-form>label{
  width: 100%;
  margin-top: 1.5rem;
  text-align: left;
}
.quiz-form input[type="email"]{
  background: transparent;
  width: 100%;
  padding: .5rem;
  font-size: 22px;
  color:#fff;
  border: none;
  border-bottom: 3px solid #ffa500; 
  outline: none;

}
.quiz-form input[type="email"]:focus{
  outline: none;
}

.quiz-form input[type="checkbox"]{
  width: 1.5rem;
  height: 1.5rem;
  display: none;  
  cursor: pointer;
}


.quiz-form .chk-class{
    display: grid;
    grid-auto-flow: column;
    cursor: pointer;
}
.quiz-form .checkbox-span a, a{
    color: #ffa500;
    opacity: .7;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
}
.quiz-form .checkbox-span a:hover, a{
    opacity: 1;
    
    transition: all 0.3s ease-in-out;
}
.checkbox-span{
  display: block;
  font-size: 16px;
  /* margin-left: 1rem; */
  padding-left: 2.25rem;
  position: relative;
}
            
.quiz-form input[type="checkbox"] + .checkbox-span::after{
    content: '';
    position: absolute;
    top: 0.15rem;
    left: 0;
    width: 1.25rem;
    height: 1.25rem;
    background: transparent;
    border: solid 3px #ffa500;
    border-radius: 5px;
}            
.quiz-form input[type="checkbox"]:checked + .checkbox-span::before{
    content: '';
    position: absolute;
    top: 0.25rem;
    left: 0.55rem;
    width: .3rem;
    height: .8rem;
    
    background: transparent;
    border: solid 4px #ffa500;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
}
.error-text{
  color: orangered;
  font-style: italic;
  font-weight: 500;
  text-align: left;
  width: 100%;
  padding-top: .25rem;
}
.quiz-form label{
    position: relative;
    display: inline-block;
    
}
.input-underline{
  transition: all 0.35s linear;
  display: inline-block;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 3px;
  
  background-color: #fff;
}
input[type=email]:focus + .input-underline{
  left: 50%;
  width: 0%;
  background-color: #ffa500;
  transition: all 0.35s linear;
} 
  
.quiz-form label>.input-label{
    bottom: .5rem;
    left: .5rem;
    position: absolute;
    z-index: -1;

}
.input-label-anim{
    animation: pullup 0.5s ease 1 forwards;
}
.input-label-anim-reverse{
    animation: pullup 0.3s ease 1 forwards reverse;
}
@keyframes pullup {
    100%
    {
        color: #ffa500;
        bottom: 2rem;
    }
}


  input[type=email]:focus > .input-label{
    top: 1.5rem;
  }
.quiz-form button, .quiz-farewell button{
    margin-top: 2rem;
    padding: .5rem;
    width: 100%;
    font-size: 22px;
    font-weight: 500;
    border-radius: 5px;
    border: 1px solid transparent;
    font-family: 'Barlow', sans-serif;

}
.quiz-button-inactive{
    background: lightgray;
    color: gray;
    pointer-events: none;
      transition: all 0.35s ease-in-out;
}

.quiz-button-active{
    background: #ffa500;
    color: #000;
    pointer-events: auto;
    cursor: pointer;
      transition: all 0.35s ease-in-out;
}
.quiz-button-active:hover, .quiz-farewell button:hover {
    background: orangered;
    color: #fff;
    pointer-events: auto;
    cursor: pointer;
    border: 1px solid orangered ;
      transition: all 0.35s ease-in-out;
}
 .quiz-farewell button{
   width: 320px;
 }
        /* PARALLAX BLOKK */
.parallax {
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    /* overflow-x: hidden;
    overflow-y: scroll;  */
    perspective: 3000px;
    perspective-origin: center;
    
  }

  .parallax-group {
    width: 100%;
    position: relative;
    height: 500px; /* fallback for older browsers */
    height: 100vh;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;

  }

  .parallax-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .parallax-layer-base div {
    background: transparent;
  }
  .parallax-layer-base {
    -webkit-transform: translateZ(0px);
    transform: translateZ(150px) scale(1.0);
    /* border: 1px solid white; */
    z-index: 4;
    background: url('/img/quiz/bulb.png');
    background-repeat: no-repeat;
    background-position: center center;
  }

  .parallax-layer-front {
    -webkit-transform: translateZ(150px) scale(.7);
    transform: translateZ(200px) scale(1.0);
    z-index: 5;
    /* border: 1px solid red; */
    background: url('/img/quiz/dust1.png');
    background-repeat: no-repeat;
    background-position: center center;
    opacity: .2;
  }

  .parallax-layer-deep {
    -webkit-transform: translateZ(100px) scale(.8);
    transform: translateZ(100px) scale(1.0);
    z-index: 3;
    /* border: 1px solid blue; */
    background: url('/img/quiz/dust2.png');
    background-repeat: no-repeat;
    background-position: center center;
    opacity: .3;
  }
  .parallax-layer-deep::before{
    content: '';
    position:absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    max-width: 1200px;
    height: 100%;
    width: auto; 
    margin: 0 auto;
    background-position: center center;
    background: linear-gradient( to right, transparent 10%, black 90% ); 
    }
  .parallax-layer-deepest {
    -webkit-transform: translateZ(50px) scale(.9);
    transform: translateZ(50px) scale(1.0);
    z-index: 2;
    /* border: 1px solid green; */
    background: url('/img/quiz/wall-shade.jpg');
    background-repeat: no-repeat;
    background-position: center center;
   }

  .parallax-layer-deepest::before{
    content: '';
    position:absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    max-width: 1200px;
    height: 100%;
    width: auto; 
    margin: 0 auto;
    background-position: center center;
    background: radial-gradient(circle at 30% 50%, transparent 1%, black 40%);
    z-index: 2;
  }


  @media screen and (min-width: 480px) {

      .quiz-welcome, .quiz-farewell{
          left: 20%;
          width: 75%;
      }
  }
  @media screen and (min-width: 768px) {
     
  .quiz-welcome{
    left: 30%;
    top: 10%;
    width: 70%;
   }
   .countdown li span{
    font-size: 3rem;
    }
    .countdown li{

    }
  }
  @media screen and (min-width: 992px) {
  
    h1 {
    margin-top: 2rem;
  }
    .quiz-welcome>div {
      margin-top: 2rem;
      
  }
  .quiz-welcome{
    left: 40%;
    top: 10%;
    width: 60%;
}
  }
  @media screen and (min-width: 1200px) {
 
  .quiz-welcome{
    left: 50%;
    top: 10%;
    right: 10%;
    width: 50%;
    max-width: 550px;
}
  }
</style>
