 <template>
  <section id="quiz" v-if='user'>
    <div class="quiz-wrapper"
      data-columns="5"
      data-rows="1" 
      v-if="currentQ.value">
      <div class="quiz-question">
          <h1>{{currentQ.value.question}}</h1>
          <img v-if="currentQ.value.url" :src="getImageUrl" alt="" width="380" height="360" class="quiz-question-img">
      </div>
      <div class="quiz-answers">
          <form action="" class="form-answers" >
              <label v-for="item in currentQ.value.answers" :key="item.id" :for="item.id" class="quiz-radio"
              >
                  <input type="radio" class="quiz-radio-button" :id="item.id" :value="item.id" v-model="userAnswers[currentQ.value.id]"><span>{{item.answer}}</span>
              </label>
          </form>
      </div>
      <div class="quiz-prev-next-buttons">
          <button v-if="currentQ.value.id < 1" class="quiz-button-inactive">Vissza</button>
          <button v-else class="quiz-button-active" @click="prevQuestion">Vissza</button>

          <button v-if="questions.value.length - 1 == currentQ.value.id && loading" class="quiz-button-inactive">Válaszok beküldése</button>
          <button v-else-if="questions.value.length - 1  == currentQ.value.id && userAnswers[counter]"  class="quiz-button-active" @click="uploadAnswers">Válaszok beküldése</button>
          <button v-else-if="questions.value.length - 1 == currentQ.value.id && !userAnswers[counter]"  class="quiz-button-inactive">Válaszok beküldése</button>
          
          <button v-else-if="userAnswers[counter]" class="quiz-button-active" @click="nextQuestion">Tovább</button>
          <button v-else class="quiz-button-inactive">Tovább</button>
          
      </div>
    </div>
    <LoaderCircle v-if="loading"></LoaderCircle>
  </section>
</template>


<script>
import axios from "axios";
import LoaderCircle from "@/components/LoaderCircle.vue";
import { ref, reactive, computed, onMounted } from "vue";
export default {
  name: "Questions",
  props: {
      user: { type: String,
          default: '',
      },
  },
  components: {
    LoaderCircle,
  },
  setup(props, { emit }){
    const counter = ref(0)
    const loading = ref(false)
    const questions = reactive({})
    const currentQ = reactive( { 
            id: '',
            url: '',
            question: '',
            answers: [
                 {
                 id: '',
                 answer: ''
             }
            ],
        })
    const userAnswers = ref([])
    const getImageUrl = computed( () => {
        return "/img/quiz/" + currentQ.value.url
    })
           
     onMounted( async() =>
      {
        loading.value = true;
        axios.get(process.env.VUE_APP_API_URL + '?q')
        .then(response => {
       loading.value = false;
       questions.value = response.data
       getCurrentQuestion()
       })
        .catch(err =>  emit('errorOccurred', err.message) )
      }
    )
    function getCurrentQuestion(){
        currentQ.value = questions.value.find(x => x.id == counter.value)
    }
    function nextQuestion(){
        if ( counter.value < questions.value.length - 1) {
            counter.value++;
            getCurrentQuestion()
        }
    }
    function prevQuestion(){
        if (counter.value > 0 ) {
            counter.value--;
            getCurrentQuestion()
        }
    }
    function uploadAnswers(){
      const postData = {
       email: props.user,
       answers: userAnswers.value
      }
      loading.value = true;
      axios.post(process.env.VUE_APP_API_URL, postData)
      .then(response => {
        if (typeof response.data === 'string') {
          emit('uploadSuccess', true)
        } else {
             emit('errorOccurred', response.data[Object.keys(response.data)[0]])
        }
        loading.value = false;
      })
      .catch(err =>  emit('errorOccurred', err.message))
    }

    return{ counter, loading, getImageUrl, currentQ, questions, nextQuestion, prevQuestion, userAnswers, uploadAnswers }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #quiz {
    height: 100vh;
    width: 100%;
    background: black;
    position: relative;
    overflow-y: scroll;
}
img {
    display: block;
    width: 100%;
    max-width: 360px;
    height: auto;
}
.quiz-wrapper{
    width: auto;
    max-width: 1180px;
    max-height: 640px;

    
    height: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-rows: 4fr 4fr 60px;
    grid-template-columns: 1fr;
    align-items: baseline;
    justify-content: center;
    justify-items: center;
    user-select: none;
    background-color: white;
    color: #000;
    
}
.quiz-button-inactive{
    /* background: lightgray; */
    color: gray;
    pointer-events: none;
    
}

.quiz-button-active{
    background: #ffb939;
    color: #000;
    pointer-events: auto;
    cursor: pointer;
    
}
.form-answers{
    display: flex;
    flex-direction: column;
    
}
.quiz-radio{
    text-align: left;
    padding: .15rem;
    display: block;
    width: 100%;
    height: 100%;
}

.quiz-radio > span{
    display: inline-block;
    transition: all .25s ease-in-out;
    font-size: 19px;
    font-weight: 500;
}
.quiz-radio:hover > span {
    transform: translateX(.25rem);
    transition: all .25s ease-in-out;
}
.quiz-radio input{
    margin: 0 .5rem .5rem .5rem;
    
}
.quiz-radio input:hover{
    cursor: pointer;
}
.quiz-prev-next-buttons{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 100%;
    
}
.quiz-prev-next-buttons button:first-child{
    border: none;
    border-right: 1px solid gray;
    border-top: 2px solid gray;
}
.quiz-prev-next-buttons button:last-child{
    border: none;
    border-left: 1px solid gray;
    border-top: 2px solid gray;
}
.quiz-prev-next-buttons button{
    font-size: 22px;
    cursor: pointer;
    user-select: none;
    transition: all .35s ease-in-out;
    font-weight: 500;
}
.quiz-prev-next-buttons button:hover{
    color: white;
    background: green;
    transition: all .35s ease-in-out;
}
.quiz-question h1{
    font-size: 28px;
    font-weight: 500;
}
.quiz-question{
    font-size: 20px;
    padding: .75rem;
    text-align: center;
    
}
.quiz-question-img{
    padding: 1rem;
    width: auto;
    height: 100%;
    max-height: 360px;
    margin: 0 auto;
}
.quiz-radio{
    cursor: pointer;
    user-select: none;
    
}

@media screen and (min-width: 768px) {
    #quiz {
        padding-top: 4rem;
    }
    .quiz-wrapper{
        
        border-radius: 16px;
    }
}
@media screen and (min-width: 992px) {
    .quiz-prev-next-buttons button:first-child{
        border-radius: 0 0 0 15px;
}
.quiz-prev-next-buttons button:last-child{
    border-radius: 0 0 15px 0;
}
}
    </style>
