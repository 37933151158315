<template>
  <div class="partners">
    <div class="header">
      <slot name="header"></slot>
    </div>
    <div class="images">
      <slot name="images"></slot>
    </div>
    <div class="links">
      <slot name="links"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "Welcome",
  components: {

  },
  props: {
    
  },
  setup( ) {
   

    return 
  },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.images{
  display: none;
}

.partners{
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 1;
}
.header{
  padding-right: 1rem;  
}


  @media screen and (min-width: 480px) {

  }
  @media screen and (min-width: 768px) {
   
  }
  @media screen and (min-width: 992px) {
  .images{
      display: block;
      transition: all 0.35s ease-in-out;
    }
    .images:hover{
      filter: brightness(0.7);
      transition: all 0.35s ease-in-out;
    }
  .links{
    display: none;
  }
  ::v-slotted(h2){
    font-size: 22px;
  }
  .partners{
    align-items: center;
  }
  }
  @media screen and (min-width: 1200px) {
 
  }
</style>
