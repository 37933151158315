<template>
  <div class="home">
    <component :is="currentComponent" v-bind="properties"
    @authSuccess="setUserName" @uploadSuccess="sayGoodbye" @windowClosed="errorWindowClosed" @errorOccurred="errorHandler"></component>
  </div>
</template>

<script>
// @ is an alias to /src
import Welcome from "@/components/Welcome.vue";
import Questions from "@/components/Questions.vue";
import ModalErrorWindow from "@/components/ModalErrorWindow.vue";
import { ref, reactive } from "vue";

export default {
  name: "Home",
  components: {
    Welcome,
    Questions,
    ModalErrorWindow,
  },
  setup() {
    const userName = ref('')
    const currentComponent = ref('Welcome')
    const properties = reactive({})
    function setUserName(e) {
      userName.value = e
      properties.user = userName.value
      currentComponent.value = 'Questions'
    }
    function sayGoodbye(e) {
      if ( e ){ 
        currentComponent.value = 'Welcome'
        properties.goodbye = true
        }
      else {
        currentComponent.value = 'Welcome'
        properties.goodbye = false
        }
    }
    function errorWindowClosed() {
      userName.value = ''
      currentComponent.value = 'Welcome'
      properties.goodbye = false
    }
    function errorHandler(e) {
      properties.errorInfo = e
      currentComponent.value = 'ModalErrorWindow'
    }
    return { userName, properties, currentComponent, setUserName, sayGoodbye, errorWindowClosed, errorHandler }
  },
};

</script>
